<template>
  <div>
    <b-card-group>
      <b-card bg-variant="warning" border-variant="dark" class="mb-3 mr-1">
        <div>
          <b-card-title class="text-white" style="font-size: x-large"
            >Quick Stats - by Month</b-card-title
          >
          <b-card-text class="text-white" style="font-size: large">
            Order Volume {{ quickStats }}
            <b-button variant="link" :to="{ name: 'chart' }"
              >(Open Chart)</b-button
            >
          </b-card-text>
        </div>
      </b-card>

      <b-card
        bg-variant="primary"
        border-variant="dark"
        class="mb-3 text-center"
      >
        <div>
          <b-card-title class="text-white" style="font-size: x-large"
            >Order Average (per week)</b-card-title
          >
          <b-card-group deck>
            <b-card
              v-for="data in orderAverage"
              v-bind:data="data"
              v-bind:key="data.index"
              bg-variant="success"
              text-variant="white"
              class="text-center border border-dark"
            >
              <b-card-title class="text-white">{{
                data.itemName
              }}</b-card-title>
              <b-card-text style="font-size: large"
                >{{ data.quantity }} m</b-card-text
              >
            </b-card>
            <b-card
              v-if="orderAverage.length === 0"
              bg-variant="danger"
              text-variant="white"
              class="text-center"
            >
              <b-card-text style="font-size: large"
                >No Order in This Week
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>
      </b-card>
    </b-card-group>

    <b-card-group>
      <b-card
        bg-variant="primary"
        border-variant="dark"
        class="mb-3 mr-1 text-center"
      >
        <div>
          <b-card-title class="text-white" style="font-size: x-large"
            >Top 5 Product By Month</b-card-title
          >
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="my-1"
              v-for="(data, index) in topProduct"
              v-bind:data="data"
              v-bind:key="data.index"
            >
              <b-card
                class="border border-dark"
                v-if="index % 2 === 0"
                bg-variant="warning"
              >
                <b-card-title class="text-white">{{
                  data.itemName
                }}</b-card-title>
                <b-card-text class="text-white" style="font-size: large"
                  >{{ data.quantity }} m</b-card-text
                >
              </b-card>
              <b-card class="border border-dark" v-else bg-variant="success">
                <b-card-title class="text-white">{{
                  data.itemName
                }}</b-card-title>
                <b-card-text class="text-white" style="font-size: large"
                  >{{ data.quantity }} m</b-card-text
                >
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        bg-variant="warning"
        border-variant="dark"
        class="mb-3 text-center"
      >
        <div>
          <b-card-title class="text-white" style="font-size: x-large"
            >Low Stock Monitor</b-card-title
          >
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="my-1"
              v-for="(data, index) in lowStockMonitor"
              v-bind:data="data"
              v-bind:key="data.index"
            >
              <b-card
                v-if="index % 2 === 0"
                bg-variant="info"
                border-variant="dark"
              >
                <b-card-title class="text-white">{{
                  data.itemName
                }}</b-card-title>
                <b-card-text
                  style="font-size: large"
                  v-if="data.quantity <= 50"
                  class="text-danger"
                  >{{ data.quantity }} m</b-card-text
                >
                <b-card-text
                  style="font-size: large"
                  v-else
                  class="text-warning"
                  >{{ data.quantity }} m</b-card-text
                >
              </b-card>
              <b-card v-else bg-variant="info" border-variant="dark">
                <b-card-title class="text-white">{{
                  data.itemName
                }}</b-card-title>
                
                <b-card-text
                  style="font-size: large"
                  v-if="data.quantity <= 50"
                  class="text-danger"
                  >{{ data.quantity }} m</b-card-text>
                <b-card-text
                  style="font-size: large"
                  v-else
                  class="text-warning"
                  >{{ data.quantity }} m</b-card-text
                >
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>

    <div class="pt-2">
      <b-card border-variant="dark" title="">
        <div class="pt-2">
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible">
                <b-table
                  hover
                  responsive
                  small
                  head-variant="warning"
                  outlined
                  :items="actionable"
                  :fields="headers"
                  :sort-by.sync="sortBy"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :stacked="stackedStatus"
                >
                  <template #cell(orderNumber)="row">
                    {{ row.item.orderNumber }}
                  </template>
                  <template #cell(status)="row">
                    {{ row.item.props.status }}
                  </template>
                  <template #cell(detail)="row">
                    {{ row.item.item }}
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>
  
  <script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      pageOptions: [5, 10, 20, 100],
      giveTime: false,
      stackedStatus: "md",
      headers: [
        { key: "orderNumber", label: "Order Number" },
        { key: "status", label: "Status" },
        { key: "itemName", label: "Cables" },
      ],
      perPage: 5,
      currentPage: 1,
      sortBy: "",
    };
  },
  methods: {},
  computed: {
    quickStats() {
      var data = this.$store.getters["dashboard/getQuickStats"];
      data.totalSalesOrder;

      return data.totalSalesOrder;
    },

    orderAverage() {
      return this.$store.getters["dashboard/getOrderaverage"];
    },

    topProduct() {
      var data = this.$store.getters["dashboard/getTopProducts"];
      return data ? data.reverse().splice(-5).reverse() : [];
    },

    lowStockMonitor() {
      return this.$store.getters["dashboard/getLowStockMonitor"];
    },

    actionable() {
      var data = this.$store.getters["dashboard/getActionable"];

      let getAllData = data.map((el) => {
        let newItemData = el.item
          .map((x) => {
            return x.detail.name;
          })
          .join(", ");
        return {
          ...el,
          itemName: newItemData,
        };
      });
      return getAllData;
    },

    rows() {
      return this.actionable.length;
    },
  },
  created() {
    document.title = "Dashboard Commerce| Tai Sin";
  },
  mounted() {
    this.$store.dispatch("dashboard/fetchQuickStats");

    this.$store.dispatch("dashboard/fetchOrderAverage");

    this.$store.dispatch("dashboard/fetchTopProducts");

    this.$store.dispatch("dashboard/fetchLowStockMonitor");

    this.$store.dispatch("dashboard/fetchActionable");

    setTimeout(() => {
      this.giveTime = true;
    }, 300);
  },
};
</script>